/**
 * Google Tag Manager
 */
(function (window, document, i) {
	window['dataLayer'] = window[dataLayer] || [];

	window['dataLayer'].push({
		'gtm.start': new Date().getTime(),
		event: 'gtm.js',
	});

	var firstScript = document.getElementsByTagName('script')[0];
	var gtmScript = document.createElement('script');
	var dataLayer = 'dataLayer' != 'dataLayer' ? '&l=' + 'dataLayer' : '';

	gtmScript.async = true;

	// Original
	gtmScript.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dataLayer;

	firstScript.parentNode.insertBefore(gtmScript, firstScript);
})(window, document, 'GTM-K4CNSNHM');
